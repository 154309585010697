
import './App.css';
// import ReactDOM from "react-dom";
import React from 'react'
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Work from "./pages/Work";
import Welcome from "./pages/Welcome";
import About from "./pages/About";
import Shop from "./pages/Shop";
import NoPage from "./pages/NoPage";
import Footer from "./components/Footer"

function App() {
  useEffect(() => {
    document.title = "Painted Crow Art";

  });
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes  >
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Welcome />} />
            <Route path="about" element={<About />} />
            <Route path="work" element={<Work />} />
            <Route path="shop" element={<Shop />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>

      </BrowserRouter>
    </div>
  );
}
{/* <Footer /> */ }

export default App;
