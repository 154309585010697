import React from 'react';
import { useState, useEffect } from 'react';
//import logo from '../images'

function Work() {

    //function to import all gallery images
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => {
            images[item.replace('./', '')] = r(item);
            return images
        });
        return images;
    };
    //use to import images
    const images = importAll(require.context('../images/artwork', false, /\.(png|jpe?g|svg|webp)$/));



    //map images to list
    const imagesKeys = Object.keys(images)

    //create array of image elements
    const imagesGallery = imagesKeys.map(image =>
        <img className='galleryImage' key={image} src={images[image]} alt={image} />
    );


    function gallerySplitter(imagesArray) {
        //console.log("gallery length", imagesGallery.length);
        //output var
        let imgCol = [];
        //initiate count vars
        let [row, column] = [0, 0];
        for (let i = 0; i < imagesGallery.length + 1; i++) {


            //advance count
            console.log("row:", row, "column:", column)
            if (row === 4) {
                row = 0
                column++
            } else {
                row++
            }
            //console.log(imagesGallery[i])
            //console.log(imagesGallery)
        }
        return;
    }

    //split into columns of 5
    const imgGalCol = gallerySplitter(imagesGallery);
    //input array
    function dostuff() {
        //console.log("did stuff")
        // console.log(imgGalCol)
    }
    //output array of arrays
    useEffect(() => {
        dostuff()
    })
    return (
        <div className='galleryWrapper'>

            <div className='gallery'>{imagesGallery}</div>
        </div>
    );
}

export default Work;