import React from "react";
import { NavLink, BrowserRouter, Route, Link } from "react-router-dom";
//import instaIcon from '..images/assets/instaIcon48.svg'
import { ReactComponent as InstaLogo } from '../images/assets/instaIcon64.svg';
import homeIcon from '../images/assets/homeIcon64.svg'

function Navbar() {
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    return (
        <nav className='navBar'>

            <NavLink className='Home Button' to="/" ><a href="" className="homeIcon"><img className="homeIcon" src={homeIcon} alt="home" /></a> </NavLink>
            <NavLink className='navButtons' to="/about">About</NavLink>
            <NavLink className='navButtons' to="/work">Work</NavLink>
            <NavLink className='navButtons' to="/shop">Contact</NavLink>
            <InstaLogo className='instaIcon' onClick={() => openInNewTab("https://www.instagram.com/painted.crow.art/")} />

        </nav>

    );
}


// old header//

{/* <div className='headerTitle'>
<Link to="/" >Home</Link>
</div>
<nav className='navBar'> */}
export default Navbar;