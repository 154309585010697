import React from 'react';
import landingBeast from '../images/assets/landingBeast.webp'

export default function Welcome() {
    return (
        <div className='landingPage'>
            <img src={landingBeast} className='landingBeast'></img>
        </div>

    );
}
