import React from 'react';

function Shop() {
    return (
        <div className='infoBackground'>
            <h1 className="shopText">If you're interested in an original, please contact me!</h1>
            <a href= "mailto:bardicinspiration20@gmail.com" className="shopText">Email</a>
        </div>
    );
}

export default Shop;