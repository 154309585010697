import React from 'react';
import aboutMe from '../images/assets/aboutMe.webp'
function About() {
    const aboutMeBlurb = "Hello, and welcome! My name is Cristina Anne Steo, I'm an artist based in New Jersey. \n\nOil painting is my primary medium, but creating art of all kinds has been a lifelong passion of mine. I find inspiration in the world around me, drawing from my experiences and emotions to create pieces that are both personal and universal. \n\nOil painting allows me to create rich, layered textures and vivid colors that bring my subjects to life. I love the way that oil paint can be blended and manipulated, giving me the freedom to create subtle gradations of tone and texture or bold, dynamic strokes of color. My work often explores themes of nature, emotion, and the human form. I am drawn to the beauty and complexity of the natural world, and I strive to capture its essence in my paintings. Whether I am painting a landscape, a portrait, or an abstract composition, I approach each piece with a sense of wonder and curiosity. \n\nI have exhibited my work in a number of galleries, festivals and art shows, both locally and nationally, and I am always looking for new opportunities to share my creations with others. For me, art is not just about creating something beautiful; it is about connecting with others and sharing a part of myself with the world. \n\n I want to thank Lena Shiffman, Erica Robin, Phil Rubinov Jacobson and Mantra Cora for all that they have taught me along my journey to become a better artist \n\nThank you for taking the time to learn a little bit about me and my art. I hope my work inspires you and brings a little bit of beauty into your life."
    return (

        <div className='infoBackground'>
            <img className='aboutMePicture' src={aboutMe} alt="The Wizard at Work"></img>
            <div className='aboutMeText'>{aboutMeBlurb}</div>
        </div>
    );
}

export default About;